import Header from "../home/header";
import fb from '../../assets/fb_.png';
import ig from '../../assets/ig_.png';
import tt from '../../assets/tt_.png'
import th from '../../assets/th_.png'
const NotFound = () => {

    return ( <div>
        <Header />
        <div className="d-lg-flex justify-content-center align-items-center" style={{marginTop:'150px'}}>
            <div className="container d-flex flex-column justify-content-center align-items-center m-5 py-2 mx-auto">

                <span className="fs-0 material-icons text-primary">shopping_bag</span>
                <p className="fs-3 fw-lighter my-5">
                    Sorry, we couldn't find what you're looking for.
                </p>
            </div>

        </div>

        <div className="footer bg-dark py-5">
            <div className="container d-flex justify-content-center align-items-start row mx-auto">
                <div className="col-lg-3 col-md-3 d-flex flex-column align-items-start justify-content-between m-sm-4">
                    <div className="d-flex justify-content-start align-items-center flex-wrap">
                        <span className="logo text-white text-uppercase fs-3 me-2">TAJ</span>
                        <div className="d-flex justify-content-start align-items-center me-3">
                            <a href="/shop" className="me-3">Shop</a>
                            <a href="/categories" className="me-3">Categories</a>
                        </div>

                    </div>
                    <div className="d-flex flex-column justify-content-start align-items-start mt-5">
                        <span className="text-uppercase text-white text-start">subscribe</span>
                        <div className="d-flex justify-content-start align-items-center border-bottom px-0"><input type="email" placeholder="Enter your email" className="form-control bg-dark border-dark ps-0" />
                            <span className="btn material-icons text-white">east</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 d-flex flex-column justify-content-between align-items-start m-sm-4">
                    <div className=" d-flex flex-column justify-content-start align-items-start">
                        <a href="/orders">Order Status</a>
                        <a href="/delivery">Delivery</a>
                        <a href="/policy">Privacy Policy</a></div>
                    <div className="d-flex justify-content-start align-items-center mt-5">
                        <img className="social-icons me-3" src={fb} />
                        <img className="social-icons me-3" src={ig} />
                        <img className="social-icons me-3" src={tt} />
                        <img className="social-icons me-3" src={th} />
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 d-flex flex-column justify-content-between align-items-start m-sm-4">
                    <div className=" d-flex flex-column justify-content-start align-items-start">
                        <a href="/payment-options">Payment Options</a>
                        <a href="/terms">Terms of Service</a>
                        <a href="/faq">FAQ</a></div>

                </div>
            </div>

        </div>

    </div> )
}
export default NotFound;