import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './home/index'
import LoginForm from "./home/login";
import SignupForm from "./home/signup";
import NotFound from "./error/404";
import CategoriesList from "./categories";
import CategoryDetails from "./categories/category_details";
import ProductList from "./shop";
import ProductDetails from "./shop/view";
import SubCategoriesList from "./categories/subcategories";
import ShoppingCart from "./checkout/cart";
class CustomRouter extends React.Component {
    constructor( props ) {
        super( props );
        this.handleAuth = this.handleAuth.bind( this );
    }
    handleAuth() {
        
    }
    render() {
        return (
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/signin" element={<LoginForm />} />
                    <Route path="/signup" element={<SignupForm />} />
                    <Route path="/categories/:id/:subCatId" element={<SubCategoriesList />} />
                    <Route path="/categories/:id" element={<CategoryDetails />} />
                    <Route path="/categories" element={<CategoriesList />} />
                    <Route path="/shop/view/:id" element={<ProductDetails />} />
                    <Route path="/shop" element={<ProductList />} />
                    <Route path="/checkout" element={<ShoppingCart />} />
                    <Route path="*" element={<NotFound />}/>

                </Routes>
            </Router>
        );
    }
}
export default CustomRouter;
