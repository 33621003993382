import Header from "../home/header";
import Footer from "../home/footer";
import { useState, useRef, useEffect } from "react";
import useIsInViewport from "../../hooks/is_in_viewport";
import dummy from '../dummy.json';
import { useNavigate, useParams } from "react-router";
import ProductCard from '../extra/Product_card'
const CategoryDetails = () => {
    const scrollRef3 = useRef( null );
    const [ showBackToTop, setShowBackToTop ] = useState( false )
    const [ data, setData ] = useState( {} )
    const [ items, setItems ] = useState( [] )
    const params = useParams()
    const scroll3 = useIsInViewport( scrollRef3 );
    const navigate = useNavigate()
    const handleScroll = ( e ) => {
        setShowBackToTop( scroll3 )
    }
    window.addEventListener( 'scroll', ( e ) => {
        handleScroll( e )
    } )
    useEffect( () => {
        const getData = async () => {
            const categoryId = parseInt( params.id )
            setData( dummy.categories.find( d => d.id == categoryId ) );
            setItems( dummy.products.filter( p => p.category == categoryId ) )
        }
        getData()
    }, [] )
    return ( <div>
        <Header />

        <div className="container-lg row d-flex justify-content-center my-5 py-5 mx-auto">
            <p className="fs-2">{data.name}</p>
            {items.length > 0 ? items.map( item => {
                return <ProductCard size='large' key={item.id} item={item} onClick={() => { navigate( `/shop/view/${item.id}`, { state: item } ) }} />
            } ) : <span className="fs-5 fw-light">No products founds</span>}

        </div>

        <Footer reference={scrollRef3} />
        {showBackToTop ? <div id="back-to-top" className="bg-danger p-2">
            <span className="btn material-icons text-white" onClick={() => { window.scrollTo( { top: 0, left: 0, behavior: 'smooth' } ) }}>arrow_upward</span>
        </div> : null}
    </div> )
}
export default CategoryDetails;