import Header from "../home/header";
import Footer from "../home/footer";
import { useState, useRef, useEffect } from "react";
import useIsInViewport from "../../hooks/is_in_viewport";
import dummy from '../dummy.json';
import { useLocation, useNavigate, useParams } from "react-router";
import Popular from "../extra/popular";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import Util from '../../utils/utils'
const ProductDetails = () => {
    const scrollRef3 = useRef( null );
    const location = useLocation();
    const navigate = useNavigate();
    var cart = localStorage.getItem( 'cart' ) ? JSON.parse( localStorage.getItem( 'cart' ) ) : [];
    const [ showBackToTop, setShowBackToTop ] = useState( false )
    const [ data, setData ] = useState( location.state ? location.state:{} )
    const [ relatedItems, setRelatedItems ] = useState( dummy.products );
    const [ largeImage, setLargeImage ] = useState( data?.image1 )
    const [ quantity, setQuantity ] = useState( 1 )
    const [ shoppingCart, setShoppingCart ] = useState( cart );

    const params = useParams()
    const scroll3 = useIsInViewport( scrollRef3 );
    const handleScroll = ( e ) => {
        setShowBackToTop( scroll3 )
    }
    window.addEventListener( 'scroll', ( e ) => {
        handleScroll( e )
    } )
    
    useEffect( () => {
        const getData = async () => {
            const productId = parseInt( params.id )
            const product = location.state ? location.state : dummy.products.find( d => d.id == productId )
            setData( product );
            setLargeImage(product.image1)
            setRelatedItems( dummy.products );
        }
        getData()
    }, [] )

    const updateCart = () => {
        let newCart = cart.map( item => {
            let newItem = JSON.parse( JSON.stringify( item ) );
            if ( item.id == data.id ) {
                newItem.quantity = item.quantity + quantity;
                newItem.amount = newItem.quantity * Number( item.price.replaceAll( ",", "" ) )
            }
            
            return newItem;
        } );
        
        let newItem = newCart.find( item => item.id == data.id );
        if ( !newItem ) {
            newItem = data;
            newItem.quantity = quantity;
            newItem.amount = newItem.quantity * Number( data.price.replaceAll( ",", "" ) )
            newCart.push( newItem)
        }
        localStorage.setItem( 'cart', JSON.stringify( newCart ) )
        setShoppingCart( newCart );
    }
    return ( <div>
        <Header cart={shoppingCart} />

        <div className="container-lg row d-flex justify-content-center my-5 py-5 mx-auto">
            <p className="fs-2">{data.name}</p>
            <div className="d-flex justify-content-start align-items-start row">
                <div className="col-lg-6 col-md-6 col-sm-10 col-xs-10 border d-flex flex-column justify-content-between align-items-center overflow-hidden" style={{height:'100%'}}>
                    <img className="product-image my-2" src={largeImage ? largeImage : data.image1} />
                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <img className="product-thumbnail m-1 border" src={data.image1} alt={data.name} onClick={() => { setLargeImage( data.image1 ) }} />
                        <img className="product-thumbnail m-1 border" src={data.image2} alt={data.name} onClick={() => { setLargeImage( data.image2 ) }} />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-10 col-xs-10 d-flex flex-column justify-content-start align-items-start">

                    <span className="fw-lighter">Name </span><p className="fs-6">{data.name}</p>
                    <span className="fw-lighter">Description </span><p className="fs-6">{data.description}</p>
                    <span className="fw-lighter">Price</span><p className="fs-6">TZS{data.price}</p>
                    <div className="form-group text-start w-100">
                        <label htmlFor="quantity" className="fw-lighter">Quantity</label>
                        <input className="form-control" type="number" defaultValue={1} id="quantity" onChange={( e ) => { setQuantity( parseInt( e.target.value ? e.target.value.trim() : 1 ) ) }} />
                        <div className="my-4">
                            <p className="fw-lighter">Summary</p>
                            <span className="fw-lighter">
                                {quantity} * {data.name}
                            </span>
                            <br />
                            <p className="fw-lighter my-2">
                                Total: &nbsp;&nbsp;

                                <span className="fs-6 fw-bold">TZS{Util.thousandSeparator( Number( data.price?.replaceAll( ",", "" ) ) * quantity )}</span></p>
                            
                            <div className="my-4 ms-1 d-flex justify-content-start align-items-center row">
                                <span className="my-3 ms-1 btn btn-primary d-flex justify-content-start align-items-center col-lg-4 col-md-4 col-sm-5" onClick={updateCart}><span className="material-icons">shopping_bag</span>&nbsp;Add to Cart</span>
                                <span className="my-3 ms-1 btn btn-success d-flex justify-content-start align-items-center col-lg-4 col-md-4 col-sm-5"><span className="material-icons">wallet</span>&nbsp;Pay Now</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container my-4 mx-auto" >
                <p className="fs-3 fw-light my-4">Related Items</p>
                <Popular showControls={false} items={relatedItems} size='small'/>
            </div>
            {/* <div className="bg-smoke d-flex flex-column justify-content-center align-items-center my-5 overflow-none">
                <p className="fs-4 fw-light my-5 ">Related Products</p>
                <Popular showControls={false} items={dummy.products} />
            </div> */}
        </div>

        <Footer reference={scrollRef3} />
        {showBackToTop ? <div id="back-to-top" className="bg-danger p-2">
            <span className="btn material-icons text-white" onClick={() => { window.scrollTo( { top: 0, left: 0, behavior: 'smooth' } ) }}>arrow_upward</span>
        </div> : null}
    </div> )
}
export default ProductDetails;