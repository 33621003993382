import Header from "../home/header";
import { useNavigate } from "react-router";
import Footer from "../home/footer";
import { useState, useRef, useEffect } from "react";
import useIsInViewport from "../../hooks/is_in_viewport";
import ProductCard from "../extra/Product_card";
import dummy from '../dummy.json';
import Util from "../../utils/utils";
import CartItem from "./cart_item";
import PaymentConfirmation from "./payment_modal";
const ShoppingCart = ( props ) => {
    const scrollRef3 = useRef( null );
    const [ showBackToTop, setShowBackToTop ] = useState( false )
    const [ data, setData ] = useState( [] )
    const [ showPaymentForm, setShowPaymentForm ] = useState(false)
    const navigate = useNavigate()
    const scroll3 = useIsInViewport( scrollRef3 );
    const handleScroll = ( e ) => {
        setShowBackToTop( scroll3 )
    }
    window.addEventListener( 'scroll', ( e ) => {
        handleScroll( e )
    } )
    useEffect( () => {
        const getData = async () => {
            const cartData = localStorage.getItem( 'cart' ) ? JSON.parse( localStorage.getItem( 'cart' ) ) : []
            setData( cartData )
        }
        getData()
    }, [] )
    const handleClick = ( id ) => {
        navigate( `/shop/view/${id}`, { state: data.find( item => item.id == id ) } )
    }
    const handleShowPayment = () => {
        setShowPaymentForm(true)
    }
    return ( <div>
        <Header />

        <div className="container-lg row d-flex justify-content-center my-5 py-5 mx-auto" style={{ marginTop: '89px' }}>
            <p className="fs-2">Checkout</p>
            <div className="d-flex justify-content-start align-items-start row border py-3">
                {data && data.length > 0 ?<>
                <div className="col-lg-6 col-md-6 col-sm-10 col-xs-10 d-flex flex-column justify-content-start align-items-center overflow-hidden" style={{ height: '100%' }}>
                        {data.map( item => {
                            return ( <CartItem item={item} key={Util.randomKey()} /> )
                        } )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-10 col-xs-10 d-flex flex-column justify-content-start align-items-start">
                    <p className="fs-2 fw-lighter">Shopping Cart Summary</p>
                    <span className="fw-lighter">Item Count: </span><p className="fs-6">{data.length}</p>
                    <span className="fw-lighter">Description </span><p className="fs-6 text-start">{data.map( d => d.name ).join( ", " )}</p>
                    <span className="fw-lighter">Total: </span><p className="fs-5 fw-bold text-primary">TZS{Util.thousandSeparator( data.reduce( ( a, b ) => a + b.amount, 0 ) )}</p>
                    <div className="form-group text-start w-100">

                        <div className="my-4">

                            <div className="my-4 ms-1 d-flex justify-content-start align-items-center row w-100">

                                    <span className="my-3 p-3 ms-1 btn btn-primary d-flex justify-content-center align-items-center col-lg-4 col-md-4 col-sm-5" onClick={handleShowPayment} data-bs-toggle="modal" data-bs-target="#paymentModal"><span className="material-icons">wallet</span>&nbsp;Pay Now</span>
                                <span className="fs-5 col-lg-4 col-md-4 col-sm-5 btn" onClick={()=>{localStorage.removeItem('cart')}}>Clear Cart</span>
                            </div>
                        </div>
                    </div>
                    </div></> : <div className="d-flex flex-column justify-content-center align-items-center my-5 py-5"><span className="fs-5 fw-light text-center mx-auto">Your cart is empty</span>
                        <a className="btn btn-primary p-3 my-5" href="/shop">Keep Shopping</a>
                </div>}
            </div>
        </div>
        {showPaymentForm ? <PaymentConfirmation amount={data.reduce( ( a, b ) => a + b.amount, 0 )} description={data.map( d => d.name ).join( ", " )} />:<span>no</span>}
        <Footer reference={scrollRef3} />
        {showBackToTop ? <div id="back-to-top" className="bg-danger p-2">
            <span className="btn material-icons text-white" onClick={() => { window.scrollTo( { top: 0, left: 0, behavior: 'smooth' } ) }}>arrow_upward</span>
        </div> : null}
    </div> )
}
export default ShoppingCart;