
import slide1 from '../../assets/fashion.jpg';
import slide2 from '../../assets/home.jpg';
import slide3 from '../../assets/kids.jpg';
const Slider = ( props ) => {

    return ( <div id="homeSliderCaptions" className="carousel slide" style={{marginTop:'89px'}}>
        <div className="carousel-indicators my-5">
            <button type="button" data-bs-target="#homeSliderCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#homeSliderCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#homeSliderCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner">
            <div className="carousel-item active">
                <img src={slide1} className="d-block w-100" alt="..." />
                <div className="carousel-caption d-none d-md-block">
                    <h5 className='fs-1 fw-light'>Stand out and Shine</h5>
                    <p>Get the best look from our clothing collections</p>
                </div>
            </div>
            <div className="carousel-item">
                <img src={slide2} className="d-block w-100" alt="..." />
                <div className="carousel-caption d-none d-md-block">
                    <h5 className='fs-1 fw-light'>Everything for your home</h5>
                    <p>Turn your house into the best living home</p>
                </div>
            </div>
            <div className="carousel-item">
                <img src={slide3} className="d-block w-100" alt="..." />
                <div className="carousel-caption d-none d-md-block">
                    <h5 className='fs-1 fw-light'>Explore and Live</h5>
                    <p>Pick from our assorted item and enjoy life</p>
                </div>
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#homeSliderCaptions" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#homeSliderCaptions" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </div> )
}
export default Slider;