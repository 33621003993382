import Header from "../home/header";
import { useNavigate } from "react-router";
import Footer from "../home/footer";
import { useState, useRef, useEffect } from "react";
import useIsInViewport from "../../hooks/is_in_viewport";
import ProductCard from "../extra/Product_card";
import dummy from '../dummy.json';
import Util from "../../utils/utils";
const ProductList = () => {
    const scrollRef3 = useRef( null );
    const [ showBackToTop, setShowBackToTop ] = useState( false )
    const [ data, setData ] = useState( {} )
    const navigate = useNavigate()
    const scroll3 = useIsInViewport( scrollRef3 );
    const handleScroll = ( e ) => {
        setShowBackToTop( scroll3 )
    }
    window.addEventListener( 'scroll', ( e ) => {
        handleScroll( e )
    } )
    useEffect( () => {
        const getData = async () => {
            setData( Util.shuffle( dummy.products ) )
        }
        getData()
    }, [] )
    const handleClick = ( id ) => {
        navigate( `/shop/view/${id}`, { state: data.find( item => item.id == id ) } )
    }
    return ( <div>
        <Header />

        <div className="container-lg row d-flex justify-content-center my-5 py-5 mx-auto" style={{ marginTop: '89px' }}>
            {data && data.length > 0 ? data.map( item => {
                return ( <ProductCard size="large" key={item.id} item={item} onClick={( id ) => handleClick( id )} /> )
            } ) : <span className="fs-5 fw-light">No products found</span>}

        </div>

        <Footer reference={scrollRef3} />
        {showBackToTop ? <div id="back-to-top" className="bg-danger p-2">
            <span className="btn material-icons text-white" onClick={() => { window.scrollTo( { top: 0, left: 0, behavior: 'smooth' } ) }}>arrow_upward</span>
        </div> : null}
    </div> )
}
export default ProductList;