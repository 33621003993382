import Header from "../home/header";
import { useNavigate, useParams } from "react-router";
import Footer from "../home/footer";
import { useState, useRef, useEffect } from "react";
import useIsInViewport from "../../hooks/is_in_viewport";
import Util from "../../utils/utils";
import dummy from '../dummy.json';
import ProductCard from "../extra/Product_card";
const SubCategoriesList = () => {
    const scrollRef3 = useRef( null );
    const [ showBackToTop, setShowBackToTop ] = useState( false )
    const [ data, setData ] = useState( {} )
    const navigate = useNavigate()
    const scroll3 = useIsInViewport( scrollRef3 );
    const handleScroll = ( e ) => {
        setShowBackToTop( scroll3 )
    }
    window.addEventListener( 'scroll', ( e ) => {
        handleScroll( e )
    } )
    const params = useParams();
    console.log("🚀 ~ file: subcategories.js:22 ~ SubCategoriesList ~ params:", params)
    useEffect( () => {
        const getData = async () => {
            const catId = parseInt( params.id );
            const subCatId = parseInt( params.subCatId );
            setData( dummy.products.filter(p=>p.category == catId) )
        }
        getData()
    }, [] )
    return ( <div>
        <Header />

        <div className="container-lg d-flex justify-content-center align-items-center" style={{ marginTop: '150px' }}>
            {data && data.length > 0 ? data.map( product => {
                return ( <ProductCard item={product} key={Util.randomKey()} size='large' onClick={() => { navigate( `/shop/view/${product.id}`, { state: product } ) }} /> )
            } ) : <span className="fs-1 fw-lighter py-5 my-5">No data to show</span>}

        </div>
        <Footer reference={scrollRef3} />
        {showBackToTop ? <div id="back-to-top" className="bg-danger p-2">
            <span className="btn material-icons text-white" onClick={() => { window.scrollTo( { top: 0, left: 0, behavior: 'smooth' } ) }}>arrow_upward</span>
        </div> : null}
    </div> )
}
export default SubCategoriesList;