import Util from "../../utils/utils";
const CartItem = ( props ) => {
    const item = props.item;
    return ( <div className="text-start card p-3 w-100 shadow m-2">
        <span className="fw-lighter">Product Name: <a className="fs-6" href={`/shop/view/${item.id}`}>{item.name}</a></span>
        <span className="fw-lighter">Price: <span className="fs-6 fw-light">TZS{item.price}</span></span>
        <span className="fw-lighter">Quantity:  <span className="fs-6 fw-light">{item.quantity}</span></span>
        <span className="fw-lighter">Amount:  <span className="fs-6 fw-light">TZS{Util.thousandSeparator( item.amount )}</span></span>
    </div> )
}
export default CartItem;