import { DateTime } from "luxon";
import { v4 as uuid } from "uuid";
const MONTHS = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
const Util = {
    shuffle: ( array ) => {
        for ( let i = array.length - 1; i > 0; i-- ) {
            const j = Math.floor( Math.random() * ( i + 1 ) );
            const temp = array[ i ];
            array[ i ] = array[ j ];
            array[ j ] = temp;
        }
        return array;
    },
    capitalize: ( word ) => {

        let result = word;
        if ( word && typeof word === "string" && word.length > 0 ) {
            word = word.trim();
            result = "";
            if ( word.includes( " " ) ) {
                var words = word.split( " " );
                words.forEach( word => {
                    result = `${result}${word.charAt( 0 ).toUpperCase()}${word.substring( 1 )} `;
                } );
                return result;
            }
            return `${word.charAt( 0 ).toUpperCase()}${word.substring( 1 )}`;
        }
        return result;
    },
    validateEmailAddress: ( email ) => {
        const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return email.match( validEmailRegex )
    },
    validatePhone: ( phone ) => {
        let result = true;
        let charSet = [ "0", "1", "2", "3", "4", "5", "6", "7", "8", "9" ];
        if ( phone == null ) return false;
        if ( typeof ( phone ) != "string" ) phone = phone.toString();
        if ( phone.includes( "+" ) ) phone = phone.replaceAll( "+", "" );
        if ( phone.includes( " " ) ) phone = phone.replaceAll( " ", "" );
        if ( phone.includes( "-" ) ) phone = phone.replaceAll( "-", "" );

        for ( let i = 0; i < phone.length; i++ ) {
            if ( charSet.indexOf( phone[ i ] ) === -1 ) {
                result = false;
                break;
            }
        }
        if ( !result ) return result;
        if ( phone.length != 12 && phone.length != 10 ) {
            return false;
        }
        if ( phone.length === 10 && phone[ 0 ] != "0" ) {
            return false;
        }
        if ( phone.length === 12 && phone.substring( 0, 3 ) !== "255" ) {
            return false;
        }
        return result;
    },
    thousandSeparator: ( x ) => {
        return x.toString().replace( /\B(?=(\d{3})+(?!\d))/g, "," );
    },
    randomKey: () => {
        return uuid();
    },
    getMonth: ( index ) => {
        return this.MONTHS[ index ];
    },
    getMonths: () => {
        return this.MONTHS;
    },
    formatDate: ( milliseconds ) => {
        let d = new Date( milliseconds );
        return this.getMonth( d.getMonth() ) + " " + d.getDate() + ", " + d.getFullYear();//.toString().substring(2);
    },
    formatDateWithTime: ( dateString ) => {
        let d = DateTime.fromISO( dateString ).toLocaleString( DateTime.DATETIME_MED )
        return d;
    },
    getRegionalData: () => {
        return [
            {
                "id": 0,
                "name": "Bara",
                "regions": [
                    {
                        "key": "arusha",
                        "name": "Arusha",
                        "districts": [
                            {
                                "name": "Arusha CBD",
                                "wards": []
                            },
                            {
                                "name": "Arusha",
                                "wards": []
                            },
                            {
                                "name": "Karatu",
                                "wards": []
                            },
                            {
                                "name": "Longido",
                                "wards": []
                            }, {
                                "name": "Meru",
                                "wards": []
                            },
                            {
                                "name": "Monduli",
                                "wards": []
                            },
                            {
                                "name": "Ngorongoro",
                                "wards": []
                            }
                        ]
                    },
                    {
                        "key": "dar_es_salaam", "name": "Dar es Salaam",
                        "districts": [
                            {
                                "name": "Ilala CBD",
                                "wards": []
                            },
                            {
                                "name": "Kigamboni",
                                "wards": []
                            },
                            {
                                "name": "Kinondoni",
                                "wards": []
                            },
                            {
                                "name": "Temeke",
                                "wards": []
                            },
                            {
                                "name": "Ubungo",
                                "wards": []
                            }
                        ]
                    },
                    {
                        "key": "dodoma",
                        "name": "Dodoma",
                        "districts": [
                            {
                                "name": "Bahi",
                                "wards": []
                            },
                            {
                                "name": "Chamwino",
                                "wards": []
                            },
                            {
                                "name": "Chemba",
                                "wards": []
                            },
                            {
                                "name": "Dodoma",
                                "wards": []
                            },
                            {
                                "name": "Kondoa",
                                "wards": []
                            },
                            {
                                "name": "Kongwa",
                                "wards": []
                            },
                            {
                                "name": "Mpwampwa",
                                "wards": []
                            }
                        ]
                    },
                    {
                        "key": "geita",
                        "name": "Geita",
                        "districts": [
                            {
                                "name": "Bukombe",
                                "wards": []
                            },
                            {
                                "name": "Chato",
                                "wards": []
                            },
                            {
                                "name": "Geita",
                                "wards": []
                            },
                            {
                                "name": "Mbogwe",
                                "wards": []
                            },
                            {
                                "name": "Nyang'hwale",
                                "wards": []
                            },

                        ]
                    },
                    {
                        "key": "iringa",
                        "name": "Iringa",
                        "districts": [
                            {
                                "name": "Iringa Mjini",
                                "wards": []
                            },
                            {
                                "name": "Iringa Vijijini",
                                "wards": []
                            },
                            {
                                "name": "Kilolo",
                                "wards": []
                            },
                            {
                                "name": "Mafinga",
                                "wards": []
                            },
                            {
                                "name": "Mufindi",
                                "wards": []
                            },

                        ]
                    },
                    {
                        "key": "kagera",
                        "name": "Kagera",
                        "districts": [
                            {
                                "name": "Biharamulo",
                                "wards": []
                            },
                            {
                                "name": "Bukoba Mjini",
                                "wards": []
                            },
                            {
                                "name": "Bukoba Vijijini",
                                "wards": []
                            },
                            {
                                "name": "Karagwe",
                                "wards": []
                            },
                            {
                                "name": "Kyerwa",
                                "wards": []
                            },
                            {
                                "name": "Missenyi",
                                "wards": []
                            },
                            {
                                "name": "Muleba",
                                "wards": []
                            },
                            {
                                "name": "Ngara",
                                "wards": []
                            }
                        ]
                    },

                    {
                        "key": "katavi",
                        "name": "Katavi",
                        "districts": [ {
                            "name": "Mlele", "wards": []
                        }, {
                            "name": "Mpanda Mjini", "wards": []
                        }, {
                            "name": "Mpanda Vijijini", "wards": []
                        },
                        ]
                    }, {
                        "key": "kigoma",
                        "name": "Kigoma",
                        "districts": [ {
                            "name": "Buhigwe", "wards": []
                        }, {
                            "name": "Kakonko", "wards": []
                        }, {
                            "name": "Kasulu Mjini", "wards": []
                        }, {
                            "name": "Kasulu Vijijini", "wards": []
                        }, {
                            "name": "Kibondo", "wards": []
                        }, {
                            "name": "Kigoma Mjini", "wards": []
                        }, {
                            "name": "Kigoma Ujiji", "wards": []
                        }, {
                            "name": "Uvinza", "wards": []
                        },
                        ]
                    },
                    {
                        "key": "kilimanjaro",
                        "name": "Kilimanjaro",
                        "districts": [ {
                            "name": "Hai", "wards": []
                        }, {
                            "name": "Moshi Mjini", "wards": []
                        }, {
                            "name": "Moshi Vijijini", "wards": []
                        }, {
                            "name": "Mwanga", "wards": []
                        }, {
                            "name": "Rombo", "wards": []
                        }, {
                            "name": "Same", "wards": []
                        }, {
                            "name": "Siha", "wards": []
                        }
                        ]
                    },

                    {
                        "key": "lindi",
                        "name": "Lindi",
                        "districts": [ {
                            "name": "Kilwa", "wards": []
                        }, {
                            "name": "Lindi", "wards": []
                        }, {
                            "name": "Lindi Vijijini", "wards": []
                        }, {
                            "name": "Liwale", "wards": []
                        }, {
                            "name": "Nachingwea", "wards": []
                        }, {
                            "name": "Ruangwa", "wards": []
                        }, {
                            "name": "Mtama", "wards": []
                        }
                        ]
                    },
                    {
                        "key": "manyara",
                        "name": "Manyara",
                        "districts": [ {
                            "name": "Babati Mjini", "wards": []
                        }, {
                            "name": "Babati Vijijini", "wards": []
                        }, {
                            "name": "Hanang", "wards": []
                        }, {
                            "name": "Kiteto", "wards": []
                        }, {
                            "name": "Mbulu", "wards": []
                        }

                        ]
                    }, {
                        "key": "mara",
                        "name": "Mara",
                        "districts": [ {
                            "name": "Bunda", "wards": []
                        }, {
                            "name": "Butiama", "wards": []
                        }, {
                            "name": "Musoma Mjini", "wards": []
                        }, {
                            "name": "Musoma Vijijini", "wards": []
                        }, {
                            "name": "Rorya", "wards": []
                        }, {
                            "name": "Serengeti", "wards": []
                        }, {
                            "name": "Tarime", "wards": []
                        }
                        ]
                    }, {
                        "key": "mbeya",
                        "name": "Mbeya",
                        "districts": [ {
                            "name": "Busokelo", "wards": []
                        }, {
                            "name": "Chunya", "wards": []
                        }, {
                            "name": "Kyela", "wards": []
                        }, {
                            "name": "Mbarali", "wards": []
                        }, {
                            "name": "Mbeya CBD", "wards": []
                        }, {
                            "name": "Mbeya Vijijini", "wards": []
                        }, {
                            "name": "Rungwe", "wards": []
                        }
                        ]
                    }, {
                        "key": "morogoro",
                        "name": "Morogoro",
                        "districts": [ {
                            "name": "Gairo", "wards": []
                        }, {
                            "name": "Kilombero", "wards": []
                        }, {
                            "name": "Kilosa", "wards": []
                        }, {
                            "name": "Malinyi", "wards": []
                        }, {
                            "name": "Morogoro Mjini", "wards": []
                        }, {
                            "name": "Morogoro Vijijini", "wards": []
                        }, {
                            "name": "Mvomero", "wards": []
                        }, {
                            "name": "Ulanga", "wards": []
                        },
                        ]
                    },
                    {
                        "key": "mtwara",
                        "name": "Mtwara",
                        "districts": [ {
                            "name": "Masasi Mjini", "wards": []
                        }, {
                            "name": "Masasi Vijijini", "wards": []
                        }, {
                            "name": "Mtwara Mjini", "wards": []
                        }, {
                            "name": "Mtwara Vijijini", "wards": []
                        }, {
                            "name": "Nanyumbu", "wards": []
                        }, {
                            "name": "Newala", "wards": []
                        }, {
                            "name": "Tandahimba", "wards": []
                        }
                        ]
                    },
                    {
                        "key": "mwanza",
                        "name": "Mwanza",
                        "districts": [ {
                            "name": "Ilemela", "wards": []
                        }, {
                            "name": "Kwimba", "wards": []
                        }, {
                            "name": "Magu", "wards": []
                        }, {
                            "name": "Misungwi", "wards": []
                        }, {
                            "name": "Nyamagana", "wards": []
                        }, {
                            "name": "Sengerema", "wards": []
                        }, {
                            "name": "Ukerewe", "wards": []
                        }
                        ]
                    },
                    {
                        "key": "njombe",
                        "name": "Njombe",
                        "districts": [ {
                            "name": "Ludewa", "wards": []
                        }, {
                            "name": "Makambako", "wards": []
                        }, {
                            "name": "Makete", "wards": []
                        }, {
                            "name": "Njombe Mjini", "wards": []
                        }, {
                            "name": "Njombe Vijijini", "wards": []
                        }, {
                            "name": "Wanginge", "wards": []
                        }
                        ]
                    },
                    {
                        "key": "pwani",
                        "name": "Pwani",
                        "districts": [ {
                            "name": "Bagamoyo", "wards": []
                        }, {
                            "name": "Kibaha Mjini", "wards": []
                        }, {
                            "name": "Kibaha Vijijini", "wards": []
                        }, {
                            "name": "Kisarawe", "wards": []
                        }, {
                            "name": "Mafia", "wards": []
                        }, {
                            "name": "Mkuranga", "wards": []
                        }, {
                            "name": "Rufiji", "wards": []
                        }
                        ]
                    },
                    {
                        "key": "rukwa",
                        "name": "Rukwa",
                        "districts": [ {
                            "name": "Kalambo", "wards": []
                        }, {
                            "name": "Nkasi", "wards": []
                        }, {
                            "name": "Sumbawanga Mjini", "wards": []
                        }, {
                            "name": "Sumbawanga Vijijini", "wards": []
                        }
                        ]
                    },
                    {
                        "key": "ruvuma",
                        "name": "Ruvuma",
                        "districts": [ {
                            "name": "Mbinga", "wards": []
                        }, {
                            "name": "Namtumbo", "wards": []
                        }, {
                            "name": "Nyasa", "wards": []
                        }, {
                            "name": "Songea Mjini", "wards": []
                        }, {
                            "name": "Songea Vijijini", "wards": []
                        }
                        ]
                    },
                    {
                        "key": "shinyanga",
                        "name": "Shinyanga",
                        "districts": [ {
                            "name": "Kahama Mjini", "wards": []
                        }, {
                            "name": "Kahama Vijijini", "wards": []
                        }, {
                            "name": "Kishapu", "wards": []
                        }, {
                            "name": "Shinyanga Mjini", "wards": []
                        }, {
                            "name": "Shinyanga Vijijini", "wards": []
                        }
                        ]
                    },
                    {
                        "key": "simiyu",
                        "name": "Simiyu",
                        "districts": [ {
                            "name": "Bariadi", "wards": []
                        }, {
                            "name": "Buswega", "wards": []
                        }, {
                            "name": "Itilima", "wards": []
                        }, {
                            "name": "Maswa", "wards": []
                        }, {
                            "name": "Meatu", "wards": []
                        }
                        ]
                    },
                    {
                        "key": "singida",
                        "name": "Singida",
                        "districts": [ {
                            "name": "Ikungi", "wards": []
                        }, {
                            "name": "Iramba", "wards": []
                        }, {
                            "name": "Manyoni", "wards": []
                        }, {
                            "name": "Mkalama", "wards": []
                        }, {
                            "name": "Singida Mjini", "wards": []
                        }, {
                            "name": "Singida Vijijini", "wards": []
                        }
                        ]
                    },
                    {
                        "key": "songwe",
                        "name": "Songwe",
                        "districts": [ {
                            "name": "Ileje", "wards": []
                        }, {
                            "name": "Mbozi", "wards": []
                        }, {
                            "name": "Momba", "wards": []
                        }, {
                            "name": "Songwe", "wards": []
                        }
                        ]
                    },
                    {
                        "key": "tabora",
                        "name": "Tabora",
                        "districts": [ {
                            "name": "Igunga", "wards": []
                        }, {
                            "name": "Kaliua", "wards": []
                        }, {
                            "name": "Nzega", "wards": []
                        }, {
                            "name": "Sikonge", "wards": []
                        }, {
                            "name": "Tabora", "wards": []
                        }, {
                            "name": "Urambo", "wards": []
                        }, {
                            "name": "Uyui", "wards": []
                        }
                        ]
                    },
                    {
                        "key": "tanga",
                        "name": "Tanga",
                        "districts": [ {
                            "name": "Handeni", "wards": []
                        }, {
                            "name": "Kilindi", "wards": []
                        }, {
                            "name": "Korogwe", "wards": []
                        }, {
                            "name": "Lushoto", "wards": []
                        }, {
                            "name": "Muheza", "wards": []
                        }, {
                            "name": "Mkinga", "wards": []
                        }, {
                            "name": "Pangani", "wards": []
                        }, {
                            "name": "Tanga", "wards": []
                        }
                        ]
                    }
                ]
            },
            {
                "id": 1,
                "name": "Zanzibar",
                "regions": [
                    {
                        "key": "kaskazini_pemba",
                        "name": "Kaskazini Pemba",
                        "districts": [ {
                            "name": "Micheweni", "wards": []
                        }, {
                            "name": "Wete", "wards": []
                        },
                        ]
                    },
                    {
                        "key": "kaskazini_unguja",
                        "name": "Kaskazini Unguja",
                        "districts": [ {
                            "name": "Kaskazini A", "wards": []
                        }, {
                            "name": "Kaskazini B", "wards": []
                        },
                        ]
                    },
                    {
                        "key": "kusini_pemba",
                        "name": "Kusini Pemba",
                        "districts": [ {
                            "name": "Chake chake", "wards": []
                        }, {
                            "name": "Mkoani", "wards": []
                        }
                        ]
                    },
                    {
                        "key": "kusini_unguja",
                        "name": "Kusini Unguja",
                        "districts": [ {
                            "name": "Kati", "wards": []
                        }, {
                            "name": "Kusini", "wards": []
                        }
                        ]
                    },
                    {
                        "key": "mjini_magharibi",
                        "name": "Mjini Magharibi",
                        "districts": [ {
                            "name": "Magharibi A", "wards": []
                        }, {
                            "name": "Magharibi B", "wards": []
                        }, {
                            "name": "Mjini", "wards": []
                        }
                        ]
                    },
                ]
            }
        ]
    },
    randomId: () => {
        var chars = [ "a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,0,1,2,3,4,5,6,7,8,9" ];
        let result = "";
        for ( let i = 0; i < 6; i++ ) {
            result += chars[ Math.floor( Math.random() * chars.length ) ];
        }

        return result;
    },

    generateRandomColors: ( count ) => {
        let result = [];
        if ( count && count > 0 ) {
            for ( let i = 0; i < count; i++ ) {
                result.push( `#${Math.floor( Math.random() * 16777215 ).toString( 16 )}` );
            }

        }
        else result.push( `#${Math.floor( Math.random() * 16777215 ).toString( 16 )}` );
        return result;
    },
}
export default Util;