
const PaymentConfirmation = ( props ) => {
    
    return (
        <div className="modal fade mx-auto" id="paymentModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="paymentModalLabel">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="paymentModalLabel">Confirm Payment</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className="text-start">
                            <div className="form-group p-3">
                                <label htmlFor="phone">Phone Number</label>
                                <input type="phone" id="phone" placeholder="07xxxxxxxx" className="form-control"/>
                            </div>
                            <div className="form-group p-3">
                                <label htmlFor="amount">Amount (TZS)</label>
                                <input type="number" id="amount" placeholder="amount in TZS" className="form-control" defaultValue={props.amount} disabled/>
                            </div>
                            <div className="form-group p-3">
                                <label htmlFor="description">Description</label>
                                <textarea id="description" placeholder="short description" className="form-control" defaultValue={props.description}></textarea>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Pay</button>
                    </div>
                </div>
            </div>
        </div>)
}
export default PaymentConfirmation;