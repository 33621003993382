import './App.css';
import CustomRouter from './components/router';

function App() {
  document.title = 'Taj - Your New Look';
  localStorage.setItem( 'language', 'en' );
  return (
    <div className="App">
      {/* <Login /> */}
      <CustomRouter />
    </div>
  );
}

export default App;
