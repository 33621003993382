import { useState, useEffect } from 'react';
import logo from '../../assets/logo.png'
import logo_dark from '../../assets/logo_dark.png'
import { useNavigate } from 'react-router';
import DropDownMenu from './drop_down_menu';
const Header = ( props ) => {
    const [ showSearch, setShowSearch ] = useState( false );
    const [ showMenu, setShowMenu ] = useState( false )
    const [ showLeftSearch, setShowLeftSearch ] = useState( false )
    const [ cartCount, setCartCount ] = useState( props.cart ? props.cart.length : null )
    const [ showDropDown, setShowDropDown ] = useState( false );
    const navigate = useNavigate()

    const handleSearch = ( e ) => {
        e.preventDefault();
        const searchInput = document.getElementById( 'search' )
        const search = searchInput.value.trim();
    }

    const handleMouseOver = () => {
        setShowDropDown( true );
        // const expandArrow = document.getElementById( 'menu-expand' );
        // expandArrow.textContent = 'expand_less';
    }
    const handleMouseOut = () => {
        setShowDropDown( false );
        // const expandArrow = document.getElementById( 'menu-expand' );
        // expandArrow.textContent = 'expand_more';
    }
    document.addEventListener( 'click', ( e ) => {
           if ( e.target.id != 'sub-menu'){
            setShowDropDown( false );
            // const expandArrow = document.getElementById( 'menu-expand' );
            // expandArrow.textContent = 'expand_more';
        }
       
    } )
    const handleCategoryExpand = ( e ) => {
        const target = e.target;
        if ( target.textContent == 'expand_more') {
            target.textContent = 'expand_less';
            setShowDropDown(true)
        }
        if ( target.textContent == 'expand_less' ) {
            target.textContent = 'expand_more';
            setShowDropDown( false )
        }
        // alert('clicked')
    }
    const handleShowCart = () => {
        navigate('/checkout',{replace:false})
    }
    window.addEventListener( 'storage', ( event ) => {
        if ( event.key === 'cart' ) {
            const cart = event.newValue;
            if ( cart ) {
                let shoppingCart = JSON.parse( cart );
                setCartCount( shoppingCart.length )
            }
            else setCartCount( 0 );
        }
    })
    useEffect( () => {
        const user = sessionStorage.getItem( 'session' );
        var shoppingCart = [];
        // if ( user ) {
        const cart = localStorage.getItem( 'cart' );
        if ( cart ) {
            shoppingCart = JSON.parse( cart );
            setCartCount( shoppingCart.length )
        }
        // }
    }, [ cartCount ] )
    return ( <div className='border-bottom top-bar w-100' style={{ position: 'fixed', top: 0,zIndex:99999999999}}>
        <div className="d-flex justify-content-between align-items-center container-lg d-none d-lg-block mx-auto" id='navigation-bar'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex justify-content-start align-items-center'>
                    {/* <img className="logo" alt='taj logo' src={logo} /> */}
                    <span className='fs-3 text-uppercase text-dark btn'><img className="logo" src={logo} alt='taj logo' onClick={() => { navigate( '/' ) }} /></span>
                    <div className='navigation d-flex justify-content-start align-items-center'>
                        <a className='nav-link m-2 active' href='/'>Home</a>
                        <a className='nav-link m-2' href='/shop'>Shop</a>
                        <span className=' d-flex align-items-center'><a className='nav-link m-2' href='/categories' onMouseOver={handleMouseOver} >Categories</a></span>
                        {/* <a className='nav-link' href='/'></a> */}
                    </div>
                </div>
                <div className='action-menus d-flex justify-content-end align-items-end my-0 px-4'>
                    <form className='d-flex justify-content-start align-items-center p-4' onSubmit={handleSearch}>
                        {showLeftSearch ? <input type='search' placeholder='type for search' className='border border-0' id='search' autoFocus={true} /> : null}
                        <span className='material-icons' onClick={() => setShowLeftSearch( !showLeftSearch )}>search</span>
                    </form>
                    <div className='p-4 menu'>
                        <span className='material-icons'>favorite</span>
                    </div>
                    <div className='p-4 menu d-flex justify-content-around align-items-center'>
                        <span className='material-icons'>shopping_bag</span><span id="cart-count" className='mb-3 fw-bold' onClick={handleShowCart}>{cartCount}</span>
                    </div>
                    <div className='p-4 menu'>
                        <span className='material-icons'>person</span>
                    </div>
                </div>
            </div>


        </div>
        <div className="d-flex flex-column justify-content-between align-items-center d-lg-none border" id='navigation-bar'>
            <div className='d-flex justify-content-between align-items-center bg-dark text-white w-100'>

                <span className='fs-3 text-uppercase text-white ms-4 btn'><img className='logo' src={logo_dark} alt='taj logo' onClick={() => { navigate( '/' ) }} /></span>

                <span className='material-icons' onClick={() => { setShowMenu( !showMenu ) }}>menu</span>
            </div>
            <div className='action-menus d-flex justify-content-between align-items-center my-0 px-4 w-100'>
                <div className='d-flex justify-content-even align-items-center p-2'>
                    <span className='material-icons' onClick={() => setShowSearch( !showSearch )}>search</span>
                    {/* <input type='text' placeholder='type for search' className='border border-0' id='search' /> */}
                </div>
                <div className='p-2 menu'>
                    <span className='material-icons'>favorite</span>
                </div>
                <div className='p-2 menu d-flex justify-content-around align-items-center'>
                    <span className='material-icons'>shopping_bag</span><span id="cart-count" className='mb-3 fw-bold' onClick={handleShowCart}>{cartCount}</span>
                </div>
                <div className='p-2 menu'>
                    <span className='material-icons'>person</span>
                </div>
            </div>
            {showSearch ?
                <form className='d-flex justify-content-start align-items-center text-white w-100' onSubmit={handleSearch}>
                    <input type='text' placeholder='type for search' className='border border-0 form-control' id='search' />
                    <span type="submit" className='btn material-icons bg-primary text-white' onClick={handleSearch}>search</span>
                </form> : null}
            {showMenu ? <div className='navigation d-flex flex-column justify-content-start align-items-start w-100'>
                <a className='nav-link my-2 active' href='/'>Home</a>
                <a className='nav-link my-2' href='/shop'>Shop</a>
                <a className='nav-link my-2' href='/categories'>Categories</a>
                {/* <a className='nav-link' href='/'></a> */}
            </div> : null}
        </div>
        {showDropDown ? <div id="sub-menu" style={{ position: 'fixed', top: '88px',zIndex:99999999999}} className='w-100 d-flex justify-content-start align-items-start bg-white border' onMouseOut={handleMouseOut} onMouseOver={handleMouseOver}>
            <DropDownMenu />
        </div> : null}
    </div> )
}
export default Header;