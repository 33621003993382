import Header from "../home/header";
import { useNavigate } from "react-router";
import Footer from "../home/footer";
import { useState, useRef, useEffect } from "react";
import useIsInViewport from "../../hooks/is_in_viewport";
import Util from "../../utils/utils";
import dummy from '../dummy.json';
import CategoryCard from "./category_card";
const CategoriesList = () => {
    const scrollRef3 = useRef( null );
    const [ showBackToTop, setShowBackToTop ] = useState( false )
    const [ data, setData ] = useState( {} )
    const navigate = useNavigate()
    const scroll3 = useIsInViewport( scrollRef3 );
    const handleScroll = ( e ) => {
        setShowBackToTop( scroll3 )
    }
    window.addEventListener( 'scroll', ( e ) => {
        handleScroll( e )
    } )
    useEffect( () => {
        const getData = async () => {
            setData( dummy )
        }
        getData()
    }, [] )
    return ( <div>
        <Header />

        <div className="container-lg d-flex justify-content-center align-items-center" style={{ marginTop: '150px' }}>
            {data.categories && data.categories.length > 0 ? data.categories.map( category => {
                return ( <CategoryCard item={category} key={Util.randomKey()} /> )
            } ) : null}

        </div>
        <Footer reference={scrollRef3} />
        {showBackToTop ? <div id="back-to-top" className="bg-danger p-2">
            <span className="btn material-icons text-white" onClick={() => { window.scrollTo( { top: 0, left: 0, behavior: 'smooth' } ) }}>arrow_upward</span>
        </div> : null}
    </div> )
}
export default CategoriesList;