import Header from "./header";
import trending from "../../assets/trending.webp"
import Popular from "../extra/popular";
import { useState, useRef, useEffect } from "react";
import useIsInViewport from "../../hooks/is_in_viewport";
import { useNavigate } from "react-router";
import dummy from '../dummy.json';
import Footer from "./footer";
import Slider from "./slider";
import CategoryCard from "../categories/category_card";
import Util from "../../utils/utils";
const Home = () => {
    const navigate = useNavigate();
    const scrollRef1 = useRef( null );
    const scrollRef2 = useRef( null );
    const scrollRef3 = useRef( null );
    const [ showBackToTop, setShowBackToTop ] = useState( false )
    const [ data, setData ] = useState( {} )
    const scroll1 = useIsInViewport( scrollRef1 );
    const scroll2 = useIsInViewport( scrollRef2 );
    const scroll3 = useIsInViewport( scrollRef3 );
    const handleScroll = ( e ) => {
        setShowBackToTop( scroll1 || scroll2 || scroll3 )
    }
    window.addEventListener( 'scroll', ( e ) => {
        handleScroll( e )
    } )
    useEffect( () => {
        const getData = async () => {
            setData( dummy )
        }
        getData()
    }, [] )
    return ( <div>
        <Header />
       
        <Slider />

        <div className="container-lg d-flex justify-content-center align-items-center" style={{marginTop:'150px'}}>
            {data.categories && data.categories.length > 0 ? data.categories.map( category => {
                return ( <CategoryCard item={category} key={Util.randomKey()} /> )
            } ) : null}

        </div>
        <div className="row justify-content-center my-5 bg-white  bg-trending" ref={scrollRef1}>
            <div className="trending shadow row mx-auto">
                <img src={trending} alt="trending" className="col-lg-6 m-0" />
                <div className="d-flex flex-column justify-content-center align-items-center col-lg-4 m-4 text-center">
                    <span className="fs-4 text-danger fw-bold">-60%</span>
                    <p className="fs-2">On trend</p>
                    <span className="btn-primary btn text-uppercase px-4 py-2">buy</span>
                </div>
            </div>
        </div>
        <div className="container my-4" ref={scrollRef2}>
            <p className="fs-3 fw-light my-4">Popular Styles</p>
            <Popular showControls={false} items={dummy.products} size='large'/>
        </div>
        <Footer reference={scrollRef3} />
        {showBackToTop ? <div id="back-to-top" className="bg-danger p-2">
            <span className="btn material-icons text-white" onClick={() => { window.scrollTo( { top: 0, left: 0, behavior: 'smooth' } ) }}>arrow_upward</span>
        </div> : null}
    </div> )
}
export default Home;