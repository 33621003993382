import Util from "../../utils/utils"
import dummy from '../dummy.json'

const DropDownMenu = ( props ) => {
    const categories = dummy.categories;
    
    return ( <div className='container-lg d-flex justify-content-between align-items-start bg-white'>
        {categories.map( category => {
            return ( <div key={Util.randomKey()} className="d-flex flex-column justify-content-start align-items-start mx-4 my-2">
                <p className="fs-6">{category.name}</p>
                {category.subcategories.map( subcategory => {
                    return <a key={Util.randomKey()} className="fw-lighter nav-link" href={`/categories/${category.id}/${subcategory.id}`}>{subcategory.name}</a>
                } )
                }
            </div>)
        })}
    </div> )
}
export default DropDownMenu;