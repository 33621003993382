import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import ProductCard from './Product_card';
import { useNavigate } from 'react-router';
import Util from '../../utils/utils';
function Popular( props ) {
    const [ items, setItems ] = React.useState( Util.shuffle(props.items) );
    const navigate = useNavigate()

    const handleClick =
        ( id ) => {
            navigate( `/shop/view/${id}`, { state: items.find( item => item.id == id ) } )
        }

    return (
        props.showControls ?
            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                {items.map( item => (
                    <ProductCard size={props.size}
                        item={item}
                        key={item.id}
                        onClick={() => handleClick( item.id )}
                    />
                ) )}
            </ScrollMenu> : <ScrollMenu >
                {items.map( item => (
                    <ProductCard size={props.size}
                        item={item}
                        key={item.id}
                        onClick={() => handleClick( item.id )}
                    />
                ) )}
            </ScrollMenu>
    );
}

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
        React.useContext( VisibilityContext );

    return (
        <Arrow direction={`left`} disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            Left
        </Arrow>
    );
}

function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext( VisibilityContext );

    return (
        <Arrow direction={`right`} disabled={isLastItemVisible} onClick={() => scrollNext()}>
            Right
        </Arrow>
    );
}

function Arrow( { direction, disabled, onClick } ) {
    return ( <span disabled={disabled} onClick={onClick} className='material-icons btn'>{`chevron_${direction}`}</span> )
}
export default Popular;