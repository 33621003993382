import React from 'react';
import Utils from '../../utils/utils';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { useNavigate } from 'react-router';

function ProductCard( { onClick,size, item } ) {
    const changeImage = () => {
        const image = document.getElementById( `card_item_${item.id}` );
        if ( image.src == item.image1 && item.image2 ) {
            image.src = item.image2;
        }
        else if ( image.src == item.image2 ) {
            image.src = item.image1;
        }
    }
    return (
        <div
            onClick={()=>onClick(item.id)}
            style={{
                width: size == 'small' ? '200px' : '300px',
            }}
            tabIndex={0}
        >
            <div className="card p-0 m-2">
                <div className='card-head' onMouseOver={changeImage} onMouseOut={changeImage}>
                    <img src={item.image1} className={"card-img-" + size} alt="taj accessories" id={`card_item_${item.id}`} />

                </div>
                <div className="card-body text-start">
                    {/* <p className="fs-5 text-uppercase text-black">title</p> */}
                    {size == 'small' ? null : <p className={"fw-lighter fs-6"}>{item.name}</p>}
                    <div className='d-flex justify-content-between align-items-center'>
                        <span className="fs-6 fw-bold text-uppercase text-black">TZS{Utils.thousandSeparator(item.price.replaceAll( ",", "" ).replaceAll(" ","") )}</span>
                        {size == 'small' ? null : <span className='btn btn-primary text-uppercase px-3' onClick={()=>onClick(item.id)}>buy</span>}</div>
                </div>
            </div>
        </div>
    );
}

export default ProductCard;