import { useNavigate } from "react-router";
import gameCover from '../../assets/games.jpg';
import homeCover from '../../assets/home_.jpg';
import clothingCover from '../../assets/clothing.png'
const CategoryCard = ( props ) => {
    const navigate = useNavigate()
    let category = props.item;
    if ( !category.cover || category.cover == "" ) {
        
        if ( category.name.toLowerCase().includes( 'fashion' ) )
            category.cover = clothingCover;
        else if ( category.name.toLowerCase().includes( 'home' ) )
            category.cover = homeCover;
        else if ( category.name.toLowerCase().includes( 'gaming' ) )
            category.cover = gameCover;
        else category.cover = homeCover
    }
    return ( <div className={`btn card col-md-3 col-lg-3 col-sm-12 p-0 m-2`}>
            <img src={category.cover} alt={category.name} className="card-img-top"/>
        <p className="btn fs-5 fw-lighter nav-link" onClick={() => { navigate( `/categories/${category.id}` ) }}>{category.name}</p>
        </div> )
}
export default CategoryCard;